.slider-overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 666;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  display: none;
  box-sizing: border-box;

  &.is-visible {
    display: flex;
  }

  .slider-close {
    position: absolute;
    top: 25px;
    right: 40px;
    width: 35px;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 100%;
    }
  }

  .slider-overlay__container {
    min-width: 0 !important;
    width: 100%;
  }

  .slick-initialized .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  .slick-slide::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .slick-slide img {
    vertical-align: middle;
    display: inline-block;
    max-height: 90vh;
    max-width: 80vw;
    margin: 0 auto;
    width: auto;
  }

  ul {
    position: absolute;
    z-index: 667;
    height: 100vh;
    width: 100vw;

    li {
      margin-top: -11px;

      &:hover {
        cursor: pointer;
      }

      &.overlay-prev {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 50vw;
        z-index: 668;

        &:hover {
          cursor: url("/uploads/slider-arrow-left.svg"), auto;
        }
      }
      &.overlay-next {
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        width: 50vw;
        z-index: 668;

        &:hover {
          cursor: url("/uploads/slider-arrow-right.svg"), auto;
        }
      }
    }
  }
}
