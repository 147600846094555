.project,
.feed,
.info,
.contact,
.about {
  .wrapper {
    @media (max-width: $l) {
      display: block;
    }
    @media (max-width: $m) {
      padding: $grid-gutter / 2;
    }
  }

  .header {
    position: relative;
    @media (max-width: $l) {
      width: 100%;
      max-width: none;
      flex: inherit;
    }

    .full-logo-wrapper {
      @media (max-width: $l) {
        margin-top: 0;
        margin-bottom: $grid-gutter;
      }

      @media (max-width: $s) {
        margin-bottom: 0;
      }
    }
  }

  .content-container {
    flex: 1 0 60%;
    min-width: 60%;
    margin: $grid-gutter $grid-gutter / 2 0;
    @media (max-width: $l) {
      margin: $grid-gutter / 2 0 0;
    }
  }

  .hero-image {
    margin-bottom: $grid-gutter * 2;
    @media (max-width: $l) {
      margin-bottom: $grid-gutter;
    }
    .article-caption {
      @media (max-width: $l) {
        display: none;
      }
    }
  }

  .mono-logo-wrapper {
    flex: 1 0 5%;
    text-align: right;
    margin-top: 0;
    max-width: 100px;
    @media (max-width: $l) {
      display: none;
    }
  }

  .project-info {
    margin-top: $grid-gutter * 2;
    max-width: 350px;

    @media (max-width: $l) {
      max-width: 100%;
      margin-top: $grid-gutter;
      display: none;
    }

    ul {
      padding-bottom: 1em;

      p {
        padding-bottom: 0;
      }
    }

    p {
      font-size: 1em !important;
      padding-bottom: 0;
    }

    h3 {
      font-size: 1em;
      font-weight: bold;

      & + p {
        padding-bottom: 1em;
      }
    }
  }

  .project-info--mobile {
    display: none;

    @media (max-width: $l) {
      max-width: 100%;
      margin-top: $grid-gutter;
      display: block;
      margin-bottom: $grid-gutter;
    }
  }

  .project-title {
    margin-bottom: $grid-gutter / 2;
  }

  .project-tags {
    margin-bottom: $grid-gutter / 2;
  }

  .article {
    margin-bottom: $grid-gutter * 2;
  }
}

.content-blocks {
  margin-top: $grid-gutter;
  @media (max-width: $l) {
    margin-top: 0;
  }

  @media (max-width: $l) {
    .article {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.article-caption {
  display: inline-block;
  margin-top: $grid-gutter;

  &.no-caption {
    display: none;
  }

  @media (max-width: $m) {
    padding: 0 $grid-gutter / 2;
  }
}

.back-arrow {
  width: 25px;
  position: fixed;
  left: 30px;
  bottom: 100px;
  opacity: 0;

  &.is-visible {
    opacity: 1;
  }

  @media (max-width: $l) {
    display: none;
  }
}

.aa-feed {
  @media (max-width: $l) {
    display: none !important;
  }
}

.slick-slider {
  @media (max-width: $l) {
    padding: 0;
    width: 100vw;
    margin-left: -30px;
  }
  @media (max-width: $m) {
    margin-left: -15px;
  }
}

.read-more,
.read-less {
  text-transform: uppercase;
  text-decoration: none;
  font-family: $font-bold;
  font-size: 1em;
  padding-bottom: $grid-gutter;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hidden-intro {
  display: none;
}
