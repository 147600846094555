.about {
  background-color: white;
  color: black;

  #full-logo,
  #mono-logo {
    fill: black;
  }

  .back-arrow svg,
  .back-arrow--mob svg {
    fill: black;
  }

  .back-arrow {
    position: fixed;
    left: 30px;
    bottom: 30px;
    opacity: 1;

    @media (max-width: $l) {
      display: none;
    }
  }

  .project-info--info {
    span {
      margin-left: $grid-gutter;
    }

    a {
      color: black;
    }

    &-mobile {
      display: none !important;
      margin-top: 0 !important;

      @media (max-width: $l) {
        display: block !important;
        margin-bottom: $grid-gutter / 2;
        order: 2;
      }
    }
  }

  .content-block--copy {
    margin-top: 80px;

    @media (max-width: $l) {
      order: 1;
    }

    @media (max-width: $s) {
      margin-top: 30px;
    }
  }

  .content-blocks--info {
    display: flex;
    margin-top: 0;

    @media (max-width: $m) {
      flex-wrap: wrap;
    }

    .content-block--info-text {
      width: 100%;
      max-width: 850px;
      margin: auto;

      @media (max-width: $m) {
        width: 100%;
        order: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        max-width: none;
      }
    }
  }

  h3 {
    margin-bottom: 30px;

    @media (max-width: $m) {
      font-size: 1.5em;
      margin-bottom: 15px;
    }
  }

  p + h3 {
    margin-top: 30px;
  }

  p + h4 {
    margin-top: 30px;
  }

  h4 {
    font-size: 1.1em;
    font-family: $font-bold;
    margin-bottom: 1em;
  }

  strong {
    font-family: $font-body;
    display: inline-block;
    padding-bottom: calc(1em / 2);
  }
}
