.article {
  margin-bottom: $grid-gutter * 2;

  @media (max-width:$m) {
    margin-bottom: $grid-gutter!important;
  }

  &.hide {
    display: none;
  }

  &.active {
    display: flex;
  }

  h3 {
    font-size: 1.5em;

    &:last-of-type {
      margin-bottom: $grid-gutter;
    }
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    font-family: $font-bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .news a, .news a h3 {
    color: #f42434;
    text-decoration-color: #f42434;
  }
}

//
//// Default overrides for mobile

.article-image, .article-essay-bg {
  @media (max-width:$m) {
    width: 100vw!important;
    margin-left: -15px!important;
    order: 1!important;
    margin-bottom: $grid-gutter;
  }
}

.no-image {
  .article-caption {
    margin-top: 0!important;
  }
  @media (max-width: $m) {
    margin-bottom: 0!important;
  }
}


// This is to fix when a half-width image has no caption/paragraph in mobile
.no-padding {
  .article-image {
    @media (max-width: $m) {
      margin-bottom: 0;
    }
  }
}

.article-info {
  h3 {
    text-transform: none;
  }
  p {
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  @media (max-width:$m) {
    width: 100%!important;
    order: 2!important;
  }
}

//
/// Full width article

.article--full {
  flex-direction: column;

  .article-caption {
    margin-top: $grid-gutter / 1.5;

    @media (max-width: $m) {
      margin-top: $grid-gutter;
    }

    &:empty {
      margin-top: 0;
    }
  }

  .article-image {
    width: 100%;
    margin-bottom: $grid-gutter;
  }

  .article-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width:$m) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .article-desc {
      max-width: 45%;

      @media (max-width:$m) {
        max-width: 100%;
      }
    }
  }
}

//
/// 40% left image article

.article--40-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width:$m) {
    flex-direction: column;
  }

  .article-image {
    width: 45%;
    order: 1;
  }

  .article-info {
    order: 2;
    width: 45%;
  }
}

//
/// 40% left image article

.article--40-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width:$m) {
    flex-direction: column;
  }

  .article-image {
    width: 45%;
    order: 2;
  }

  .article-info {
    order: 1;
    width: 45%;
  }
}

//
/// 40% left image article

.article--60-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width:$m) {
    flex-direction: column;
  }

  .article-image {
    width: 65%;
    order: 1;
  }

  .article-info {
    order: 2;
    width: 25%;

    @media (max-width: $xl) {
      width: 30%;
    }

    @media (max-width: $l) {
      width: 25%;
    }
  }
}

//
/// 40% left image article

.article--60-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width:$m) {
    flex-direction: column;
  }

  .article-image {
    width: 65%;
    order: 2;
  }

  .article-info {
    order: 1;
    width: 25%;

    @media (max-width: $xl) {
      width: 30%;
    }

    @media (max-width: $l) {
      width: 25%;
    }
  }
}

//
/// Essay right article

.article--essay-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width:$m) {
    flex-direction: column;
  }

  .article-essay-bg {
    width: 45%;
    order: 2;
    text-align: right;

    svg {
      max-width: 100%;
    }
  }

  .article-info {
    order: 1;
    width: 45%;
  }
}

//
/// Essay left article

.article--essay-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width:$m) {
    flex-direction: column;
  }

  .article-essay-bg {
    width: 45%;
    order: 1;
    text-align: left;

    svg {
      max-width: 100%;
    }
  }

  .article-info {
    order: 2;
    width: 45%;
  }
}

//
/// Slider article

.article--slider {
  position: relative;

  ul {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    li {
      margin-top: -11px;

      &:hover {
        cursor: pointer;
      }

      &.prev {
        margin-left: -35px;

        @media (max-width: $l) {
          margin-left: 10px;
        }
        @media (max-width: $m) {
          margin-left: -5px;
        }
      }
      &.next {
        margin-right: -35px;
        @media (max-width: $l) {
          margin-right: 10px;
        }
        @media (max-width: $m) {
          margin-right: -5px;
        }
      }
    }
  }
}

.article--slider--left {
  width: 60%;
  margin-right: auto;

  @media (max-width: $l) {
    width: inherit;
  }
}

.article--slider--right {
  width: 60%;
  margin-left: auto;

  @media (max-width: $l) {
    width: inherit;
  }
}