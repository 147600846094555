//
//// Hero

.hero-wrapper {
  &.animate-color {
    animation: pulse 90s infinite;

    @keyframes pulse {
      0% {
        background-color: white;
      }
      25% {
        background-color: #f42434;
      }
      50% {
        background-color: #0032a0;
      }
      75% {
        background-color: #f42434;
      }
      100% {
        background-color: white;
      }
    }
  }
}

.hero-wrapper {
  & > .animate-color svg, .animate-color, span.animate-color {
    animation: fill 90s infinite;

    @keyframes fill {
      0% {
        color: black;
        fill: black;
        path: black;
      }
      25% {
        color: #0032a0;
        fill: #0032a0;
        path: #0032a0;
      }
      50% {
        color: #f42434;
        fill: #f42434;
        path: #f42434;
      }
      75% {
        color: #0032a0;
        fill: #0032a0;
        path: #0032a0;
      }
      100% {
        color: black;
        fill: black;
        path: black;
      }
    }
  }

  #arrow-down.animate-color polyline,
  #arrow-down.animate-color polyline a,
  #arrow-down.animate-color {
    animation: path 90s infinite;

    @keyframes path {
      0% {
        fill: black;
      }
      25% {
        fill: #0032a0;
      }
      50% {
        fill: #f42434;
      }
      75% {
        fill: #0032a0;
      }
      100% {
        fill: black;
      }
    }
  }
}