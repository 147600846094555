#news {
  min-height: 100vh;

  @media (max-width: $m) {
    min-height: 100%;
  }

  .wrapper {
    @media (max-width: $l) {
      flex-direction: column;
    }
    @media (max-width: $m) {
      padding: $grid-gutter / 2;
    }
  }

  .mob-height {
    display: none;

    @media (max-width: $m) {
      position: relative;
      height: 91px;
      display: block;
    }

    @media (max-width: $s) {
      height: 40px;
    }
  }

  .header {
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0;

    @media (max-width: $l) {
      opacity: 1;
    }

    @media (max-width: $m) {
      position: absolute!important;
      top: 0;
      left: 0;
      padding: 15px;
    }

    &.fixed {
      position: fixed;
      opacity: 1;
    }

    &.mob-fixed {
      position: fixed!important;
      top: 0;
      left: 0;
      padding: 15px;
      z-index: 999;
    }

    h3 {
      @media (max-width: $l) {
        display: none;
      }
    }

    .full-logo-wrapper {
      @media (max-width: $l) {
        margin-top: 0;
      }
    }
  }

  .mono-logo-wrapper {
    flex: 1 0 5%;
    text-align: right;
    margin-top: 0;
    max-width: 100px;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.fixed {
      position: fixed;
      right: 30px;
      opacity: 1;
    }

    @media (max-width: $l) {
      display: none;
    }
  }
}


//
//// Filter
.filter {
  opacity: 0;
  display: flex;
  transition: opacity 0.5s ease;
  margin-top: 0;
  left: 30px;
  top: 180px;

  @media (max-width: $l) {
    display: none;
  }

  &.is-visible {
    opacity: 1;
    position: fixed;
  }

  .project-filter {
    ul {
      max-width: 250px;
    }
  }

  .category-filter {
    margin-right: $grid-gutter;

    li.news.active {
      text-decoration: none!important;
      color: #f42434;
    }

    li.active {
      text-decoration: line-through;
    }
  }

  li {
    &:hover {
      cursor: pointer;
    }
    &.hide {
      display: none;
    }
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

//
//// Funky container
.home {
  .content-container {
    margin-left: 31%;
    width: 63%;
    padding: 0 $grid-gutter / 2;
    max-width: 1120px;

    @media (max-width: $xl) {
      margin-left: 35%;
      width: 59%;
    }

    @media (max-width: 1130px) {
      margin-left: 41%;
      width: 52%;
    }

    @media (max-width: $l) {
      width: 100%;
      margin-left: 0;
      padding: 0;
    }

    .article-container {
      height: calc(100vh - 60px);
    }
  }
}


//
//// Article container

.article-container {
  margin-top: $grid-gutter;
  z-index: 666;

  @media (max-width: $m) {
    margin-top: 20px;
  }
}