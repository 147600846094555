.feed {
  background-color: #0032a0;
  color: white;

  .wrapper {
    min-height: 100vh;
  }

  .project-info {
    @media (max-width: $l) {
      display: none;
    }
  }
  .project-info--mobile {
    display: block;
    @media (min-width: $l) {
      display: none;
    }
  }

  .link-container {
    padding-bottom: 60px;

    ul {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;

      @media (max-width: $l) {
        max-height: inherit;
      }

      li {
        max-width: 400px;
        width: 40%;
        min-width: 300px;
        padding-bottom: $grid-gutter;
        margin-right: $grid-gutter;

        @media (max-width: 968px) {
          min-width: 100%;
        }

        a {
          text-decoration: none;
          display: flex;

          &:hover {
            text-decoration: underline;
          }

          span {
            &:first-of-type {
              min-width: 90px;
            }
          }
        }

        figure {
          position: relative;
          padding-top: 10px;
        }

        img {
          margin-top: 8px;
          cursor: url("/uploads/noun_Plus_2310572.svg"), auto;

          @media (min-width: $m) {
            &:hover {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 666;
              width: 580px;
              padding-bottom: $grid-gutter;
            }
          }
        }
      }
    }
  }

  .back-arrow--mob,
  .h6 a,
  svg,
  a,
  p {
    color: white;
    fill: white;
  }

  .back-arrow polyline,
  .back-arrow--mob polyline {
    fill: white;
  }

  .back-arrow--mob {
    position: absolute;
    bottom: 0;
  }
  .back-arrow {
    bottom: $grid-gutter;
  }
}
