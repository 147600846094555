.essay, .default {
  .wrapper {
    min-height: 100vh;

    @media (max-width:$l) {
      flex-direction: column;
    }
    @media (max-width:$m) {
      padding: $grid-gutter / 2;
    }
  }

  header {
    @media (max-width:$l) {
      width: 100%;
      max-width: none;
      flex: inherit;
    }

    h3.h6 {
      @media (max-width:$l) {
        display: none;
      }
    }

    .full-logo-wrapper {
      @media (max-width:$l) {
        margin-top: 0;
        margin-bottom: $grid-gutter;
      }
    }
  }

  .project-title {
    margin-bottom: $grid-gutter / 2;
  }
  .project-tags {
    margin-bottom: $grid-gutter / 2;
  }

  .essay-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .essay-column {
      flex: 1 0 40%;
      margin-right: $grid-gutter;
      min-width: 300px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .content-container {
    flex: 1 0 60%;
    min-width: 60%;
    margin: 0 $grid-gutter / 2;

    @media (max-width:$l) {
      margin: $grid-gutter / 2 0 0;
    }
  }

  .mono-logo-wrapper {
    flex: 1 0 5%;
    text-align: right;
    margin-top: 0;
    max-width: 100px;

    @media (max-width:$l) {
      display: none;
    }
  }

  .back-arrow {
    position: fixed;
    left: 30px;
    bottom: 100px;
    opacity: 0;

    &.is-visible {
      opacity: 1;
    }

    @media (max-width:$l) {
      display: none;
    }
  }

  .aa-feed {
    @media (max-width:$l) {
      display: none!important;
    }
  }
}