h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
}

h1, .h1 {
  font-size: 3.125em;
}

h2, .h2 {
  font-size: 2.618em;
}

h3, .h3 {
  font-size: 1.931em;
}

h4, .h4 {
  font-size: 1.618em;
}

h5, .h5 {
  font-size: 1.194em;
}

h6, .h6 {
  font-size: 1.05em;
}

.small {
  font-size: 1em;
}

p,
label,
input,
select,
span,
button,
ul,
li {
  font-family: $font-body;
}

p {
  font-size: 1.1em;
  padding-bottom: 1em;
}

span {
  font-size: 1.1em;
}

.hero-wrapper p {
  font-size: 1em!important;
}

strong {
  font-family: $font-bold;
  font-weight: bold;
}

em {
  font-family: $font-ital;
}

a {
  color: black;
}