.contact {
  background-color: #f42434;
  color: black;

  #full-logo,
  #mono-logo {
    fill: black;
  }

  .back-arrow svg,
  .back-arrow--mob svg {
    fill: black;
  }

  .back-arrow {
    position: fixed;
    left: 30px;
    bottom: 30px;
    opacity: 1;

    @media (max-width: $l) {
      display: none;
    }
  }

  p {
    padding-bottom: 1em !important;
  }

  .content-container--info {
    @media (max-width: $m) {
      margin-top: 40px;
    }
  }
}

.project-info--info {
  span {
    margin-left: $grid-gutter;
  }

  a {
    color: black;
  }

  &-mobile {
    display: none !important;
    margin-top: 0 !important;

    @media (max-width: $l) {
      display: block !important;
      margin-bottom: $grid-gutter / 2;
      order: 2;
    }
  }
}

.content-block--copy {
  @media (max-width: $l) {
    order: 1;
  }
}

.content-blocks--info {
  display: flex;
  margin-top: 0;

  @media (max-width: $m) {
    flex-wrap: wrap;
  }

  .content-block--info-images {
    width: 50%;
    margin-right: $grid-gutter;

    @media (max-width: $m) {
      width: 100%;
      order: 2;
      margin-right: 0;
    }

    img {
      margin-bottom: $grid-gutter;
    }
  }

  .content-block--info-text {
    width: 50%;

    @media (max-width: $m) {
      width: 100%;
      order: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}
