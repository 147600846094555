.home {
  .hero-wrapper {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    display: block;

    @media (max-width: $m) {
      width: 100%;
    }

    &.hide {
      display: none;
    }

    .hero-intro {
      position: absolute;
      max-width: 307px;
      margin-top: 10px;
      margin-left: $grid-gutter;

      @media (max-width: $s) {
        margin: $grid-gutter $grid-gutter / 2;
        position: relative;
        max-width: none;
      }

      p {
        text-indent: 21px;
        padding-bottom: 0;
      }
    }

    .hero-arrow {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);

      svg {
        width: 60px;
      }

      @media (max-width: $m) {
        svg {
          width: 40px;
        }
      }
    }

    .header {
      padding: $grid-gutter;
      width: 100%;
      max-width: 100%;

      @media (max-width: $l) {
        padding: $grid-gutter * 1.5 $grid-gutter $grid-gutter / 2;
        max-width: none;
      }

      h3 {
        line-height: 1.2;

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .full-logo-wrapper {
        margin-top: 60px;
        float: left;

        @media (max-width: $l) {
          float: none;
          margin-top: 0;
          width: auto;
        }
      }

      .mono-logo-wrapper {
        margin-top: 40px;
        float: right;
      }
    }
  }

  #cursor {
    visibility: hidden !important;
  }
}
