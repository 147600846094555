.mobile-menu {
  width: 0px;
  transition: 0.5s width linear;
  display: block;
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 666;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);;
  overflow-x: hidden;
  transition: 0.3s width ease;

  .mobile-menu--close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 66666;
    width: 40px;

    @media (max-width: 600px) {
      top: 20px;
      right: 8px;
    }

    &:hover {
      cursor: pointer;
    }
    svg {
      fill: white;
    }
  }

  &.show {
    width: 300px;
  }
  .first-tier {
    padding-top: 130px;
    font-family: $font-bold;

    @media (max-width: $s) {
      padding-top: 80px;
    }

    h3.h6 {
      display: block!important;
      font-size: 1em;

      a {
        color: black!important;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .second-tier {
    padding-top: 12px;
    padding-bottom: 40px;
  }

    ul {
      padding: 0 0 20px 20px;
      width: 300px;
      li {
        display: block;
        white-space: nowrap;
        color: black!important;

        a {
          color: black!important;
          font-size: 1em;
          padding-bottom: 3px;
          text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
