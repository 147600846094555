.aa-feed {
  position: fixed;
  bottom: $grid-gutter;
  left: $grid-gutter;
  display: none;

  @media (max-width: $l) {
    display: none;
  }

  a {
    color: #f42434;
    text-decoration: none;
    display: flex;

    &.blue {
      color: #0032a0;
    }

    span {
      position: relative;
      top: -2px;
      margin-right: 4px;
    }
  }

  &.is-visible {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}

.aa-feed__modal {
  display: none;
  background-color: #0032a0;
  color: white;
  padding: $grid-gutter;
  position: fixed;
  bottom: 60px;
  left: 100px;

  ul {
    max-width: 600px;

    li {
      padding-bottom: 5px;
    }
  }

  @media (max-width: $l) {
    display: none;
  }

  &.active {
    display: block;
  }

  a {
    color: white;
    text-decoration: none;
    display: flex;

    &:hover {
      text-decoration: underline;
    }

    span {
      &:first-of-type {
        min-width: 90px;
      }
    }
  }
}

.aa-feed__heading {
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  &:first-of-type {
    margin-bottom: $grid-gutter / 2;
  }
  &:last-of-type {
    margin-top: $grid-gutter / 2;
  }
}