.default {
  .content-container {
    .project-info {
      h3 {
        font-size: 1em!important;
        font-weight: bold!important;
      }

      ul {
        margin: 0 0 $grid-gutter / 2;
      }
    }
  }

  .mono-logo-wrapper {
    flex: 1 0 5%;
    text-align: right;
    margin-top: 0;
    max-width: 100px;

    @media (max-width:$l) {
      display: none;
    }
  }
}
