@font-face {
  font-family: "favorit reg";
  src: url("../fonts/favorit-regular.woff") format("woff");
}
@font-face {
  font-family: "favorit bold";
  src: url("../fonts/favorit-bold.woff") format("woff");
}
@font-face {
  font-family: "favorit ital";
  src: url("../fonts/favorit-regular-italic.woff") format("woff");
}

$font-heading: 'favorit reg', Helvetica, Arial, sans-serif;
$font-body: 'favorit reg', Helvetica, Arial, sans-serif;
$font-ital: 'favorit ital', Helvetica, Arial, sans-serif;
$font-bold: 'favorit bold', Helvetica, Arial, sans-serif;

$grid-gutter: 30px;

$xs: 500px;
$s: 600px;
$m: 756px;
$l: 968px;
$xl: 1280px;