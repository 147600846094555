*::selection {
  color: white;
  background-color: black;
}

img {
  display: block;
  width: 100%;
}

body {
  overflow-x: hidden;
  line-height: 1.2;

  .lock-scroll {
    overflow: hidden;
  }


  // Need fixed elements so blur specifics
  .header h3 a,
  .content-container,
  .category-filter,
  .project-filter,
  .full-logo-wrapper,
  .mono-logo-wrapper svg,
  .project-info,
  .back-arrow {
    filter: blur(0);
    transition: 0.5s filter linear;
    will-change: filter;
    transform: translate3d(0);
  }
  &.blur .aa-feed,
  &.blur .aa-feed__modal {
    filter: blur(0)!important;
  }
  &.blur .header h3 a,
  &.blur .content-container,
  &.blur .category-filter,
  &.blur .project-filter,
  &.blur .full-logo-wrapper,
  &.blur .mono-logo-wrapper svg,
  &.blur .project-info,
  &.blur .back-arrow {
    filter: blur(0.5em);
  }
}

.wrapper {
  position: relative;
  padding: $grid-gutter;
  display: flex;
  height: 100%;
}

.clearfix {
  clear: both;
}

.full-logo-wrapper {
  svg {
    max-width: 300px;
    max-height: 87px;
  }
}

.mono-logo-wrapper,
.mono-logo-wrapper--mobile {
  svg {
    max-width: 57px;
    max-height: 85px;
  }
}