/*==============================================================================
HEADER
==============================================================================*/
.header {
  flex: 1 0 30%;
  max-width: 400px;

  @media (max-width: $l) {
    position: relative !important;
    display: flex;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    flex: none;
  }

  h3 {
    line-height: 1.2;

    @media (max-width: $l) {
      display: none;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .full-logo-wrapper {
    margin-top: 0;

    @media (max-width: $s) {
      height: 40px;

      svg {
        height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
  }

  .mono-logo-wrapper {
    margin-top: 0;

    @media (max-width: $l) {
      display: none;
    }
  }

  .mono-logo-wrapper--mobile {
    display: none;
    z-index: 9999999;

    &.black {
      svg {
        color: black;
        fill: black !important;
      }
    }

    @media (max-width: $l) {
      display: block !important;

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: $s) {
      height: 40px;
      width: auto;
      text-align: right;

      svg {
        height: 100%;
        width: auto;
      }
    }
  }
}

/*==============================================================================
MAIN
==============================================================================*/

.back-arrow--mob {
  display: none;

  @media (max-width: $l) {
    display: block;
  }
}

.back-arrow,
.back-arrow--mob {
  width: 45px !important;
  height: 40px !important;

  svg {
    width: 100%;
  }
}
