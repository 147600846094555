/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

@font-face {
  font-family: "favorit reg";
  src: url("../fonts/favorit-regular.woff") format("woff"); }

@font-face {
  font-family: "favorit bold";
  src: url("../fonts/favorit-bold.woff") format("woff"); }

@font-face {
  font-family: "favorit ital";
  src: url("../fonts/favorit-regular-italic.woff") format("woff"); }

*::selection {
  color: white;
  background-color: black; }

img {
  display: block;
  width: 100%; }

body {
  overflow-x: hidden;
  line-height: 1.2; }
  body .lock-scroll {
    overflow: hidden; }
  body .header h3 a,
  body .content-container,
  body .category-filter,
  body .project-filter,
  body .full-logo-wrapper,
  body .mono-logo-wrapper svg,
  body .project-info,
  body .back-arrow {
    filter: blur(0);
    transition: 0.5s filter linear;
    will-change: filter;
    transform: translate3d(0); }
  body.blur .aa-feed,
  body.blur .aa-feed__modal {
    filter: blur(0) !important; }
  body.blur .header h3 a,
  body.blur .content-container,
  body.blur .category-filter,
  body.blur .project-filter,
  body.blur .full-logo-wrapper,
  body.blur .mono-logo-wrapper svg,
  body.blur .project-info,
  body.blur .back-arrow {
    filter: blur(0.5em); }

.wrapper {
  position: relative;
  padding: 30px;
  display: flex;
  height: 100%; }

.clearfix {
  clear: both; }

.full-logo-wrapper svg {
  max-width: 300px;
  max-height: 87px; }

.mono-logo-wrapper svg,
.mono-logo-wrapper--mobile svg {
  max-width: 57px;
  max-height: 85px; }

/*==============================================================================
HEADER
==============================================================================*/
.header {
  flex: 1 0 30%;
  max-width: 400px; }
  @media (max-width: 968px) {
    .header {
      position: relative !important;
      display: flex;
      max-width: 100%;
      width: 100%;
      justify-content: space-between;
      flex: none; } }
  .header h3 {
    line-height: 1.2; }
    @media (max-width: 968px) {
      .header h3 {
        display: none; } }
    .header h3 a {
      text-decoration: none; }
      .header h3 a:hover {
        text-decoration: underline; }
  .header .full-logo-wrapper {
    margin-top: 0; }
    @media (max-width: 600px) {
      .header .full-logo-wrapper {
        height: 40px; }
        .header .full-logo-wrapper svg {
          height: 100%;
          width: auto;
          max-width: 100%; } }
  .header .mono-logo-wrapper {
    margin-top: 0; }
    @media (max-width: 968px) {
      .header .mono-logo-wrapper {
        display: none; } }
  .header .mono-logo-wrapper--mobile {
    display: none;
    z-index: 9999999; }
    .header .mono-logo-wrapper--mobile.black svg {
      color: black;
      fill: black !important; }
    @media (max-width: 968px) {
      .header .mono-logo-wrapper--mobile {
        display: block !important; }
        .header .mono-logo-wrapper--mobile:hover {
          cursor: pointer; } }
    @media (max-width: 600px) {
      .header .mono-logo-wrapper--mobile {
        height: 40px;
        width: auto;
        text-align: right; }
        .header .mono-logo-wrapper--mobile svg {
          height: 100%;
          width: auto; } }

/*==============================================================================
MAIN
==============================================================================*/
.back-arrow--mob {
  display: none; }
  @media (max-width: 968px) {
    .back-arrow--mob {
      display: block; } }

.back-arrow,
.back-arrow--mob {
  width: 45px !important;
  height: 40px !important; }
  .back-arrow svg,
  .back-arrow--mob svg {
    width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "favorit reg", Helvetica, Arial, sans-serif; }

h1, .h1 {
  font-size: 3.125em; }

h2, .h2 {
  font-size: 2.618em; }

h3, .h3 {
  font-size: 1.931em; }

h4, .h4 {
  font-size: 1.618em; }

h5, .h5 {
  font-size: 1.194em; }

h6, .h6 {
  font-size: 1.05em; }

.small {
  font-size: 1em; }

p,
label,
input,
select,
span,
button,
ul,
li {
  font-family: "favorit reg", Helvetica, Arial, sans-serif; }

p {
  font-size: 1.1em;
  padding-bottom: 1em; }

span {
  font-size: 1.1em; }

.hero-wrapper p {
  font-size: 1em !important; }

strong {
  font-family: "favorit bold", Helvetica, Arial, sans-serif;
  font-weight: bold; }

em {
  font-family: "favorit ital", Helvetica, Arial, sans-serif; }

a {
  color: black; }

.hero-wrapper.animate-color {
  animation: pulse 90s infinite; }

@keyframes pulse {
  0% {
    background-color: white; }
  25% {
    background-color: #f42434; }
  50% {
    background-color: #0032a0; }
  75% {
    background-color: #f42434; }
  100% {
    background-color: white; } }

.hero-wrapper > .animate-color svg, .hero-wrapper .animate-color, .hero-wrapper span.animate-color {
  animation: fill 90s infinite; }

@keyframes fill {
  0% {
    color: black;
    fill: black;
    path: black; }
  25% {
    color: #0032a0;
    fill: #0032a0;
    path: #0032a0; }
  50% {
    color: #f42434;
    fill: #f42434;
    path: #f42434; }
  75% {
    color: #0032a0;
    fill: #0032a0;
    path: #0032a0; }
  100% {
    color: black;
    fill: black;
    path: black; } }

.hero-wrapper #arrow-down.animate-color polyline,
.hero-wrapper #arrow-down.animate-color polyline a,
.hero-wrapper #arrow-down.animate-color {
  animation: path 90s infinite; }

@keyframes path {
  0% {
    fill: black; }
  25% {
    fill: #0032a0; }
  50% {
    fill: #f42434; }
  75% {
    fill: #0032a0; }
  100% {
    fill: black; } }

#news {
  min-height: 100vh; }
  @media (max-width: 756px) {
    #news {
      min-height: 100%; } }
  @media (max-width: 968px) {
    #news .wrapper {
      flex-direction: column; } }
  @media (max-width: 756px) {
    #news .wrapper {
      padding: 15px; } }
  #news .mob-height {
    display: none; }
    @media (max-width: 756px) {
      #news .mob-height {
        position: relative;
        height: 91px;
        display: block; } }
    @media (max-width: 600px) {
      #news .mob-height {
        height: 40px; } }
  #news .header {
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0; }
    @media (max-width: 968px) {
      #news .header {
        opacity: 1; } }
    @media (max-width: 756px) {
      #news .header {
        position: absolute !important;
        top: 0;
        left: 0;
        padding: 15px; } }
    #news .header.fixed {
      position: fixed;
      opacity: 1; }
    #news .header.mob-fixed {
      position: fixed !important;
      top: 0;
      left: 0;
      padding: 15px;
      z-index: 999; }
    @media (max-width: 968px) {
      #news .header h3 {
        display: none; } }
    @media (max-width: 968px) {
      #news .header .full-logo-wrapper {
        margin-top: 0; } }
  #news .mono-logo-wrapper {
    flex: 1 0 5%;
    text-align: right;
    margin-top: 0;
    max-width: 100px;
    opacity: 0;
    transition: opacity 0.5s ease; }
    #news .mono-logo-wrapper.fixed {
      position: fixed;
      right: 30px;
      opacity: 1; }
    @media (max-width: 968px) {
      #news .mono-logo-wrapper {
        display: none; } }

.filter {
  opacity: 0;
  display: flex;
  transition: opacity 0.5s ease;
  margin-top: 0;
  left: 30px;
  top: 180px; }
  @media (max-width: 968px) {
    .filter {
      display: none; } }
  .filter.is-visible {
    opacity: 1;
    position: fixed; }
  .filter .project-filter ul {
    max-width: 250px; }
  .filter .category-filter {
    margin-right: 30px; }
    .filter .category-filter li.news.active {
      text-decoration: none !important;
      color: #f42434; }
    .filter .category-filter li.active {
      text-decoration: line-through; }
  .filter li:hover {
    cursor: pointer; }
  .filter li.hide {
    display: none; }
  .filter li a {
    text-decoration: none; }
    .filter li a:hover {
      text-decoration: underline; }

.home .content-container {
  margin-left: 31%;
  width: 63%;
  padding: 0 15px;
  max-width: 1120px; }
  @media (max-width: 1280px) {
    .home .content-container {
      margin-left: 35%;
      width: 59%; } }
  @media (max-width: 1130px) {
    .home .content-container {
      margin-left: 41%;
      width: 52%; } }
  @media (max-width: 968px) {
    .home .content-container {
      width: 100%;
      margin-left: 0;
      padding: 0; } }
  .home .content-container .article-container {
    height: calc(100vh - 60px); }

.article-container {
  margin-top: 30px;
  z-index: 666; }
  @media (max-width: 756px) {
    .article-container {
      margin-top: 20px; } }

.home .hero-wrapper {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: block; }
  @media (max-width: 756px) {
    .home .hero-wrapper {
      width: 100%; } }
  .home .hero-wrapper.hide {
    display: none; }
  .home .hero-wrapper .hero-intro {
    position: absolute;
    max-width: 307px;
    margin-top: 10px;
    margin-left: 30px; }
    @media (max-width: 600px) {
      .home .hero-wrapper .hero-intro {
        margin: 30px 15px;
        position: relative;
        max-width: none; } }
    .home .hero-wrapper .hero-intro p {
      text-indent: 21px;
      padding-bottom: 0; }
  .home .hero-wrapper .hero-arrow {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%); }
    .home .hero-wrapper .hero-arrow svg {
      width: 60px; }
    @media (max-width: 756px) {
      .home .hero-wrapper .hero-arrow svg {
        width: 40px; } }
  .home .hero-wrapper .header {
    padding: 30px;
    width: 100%;
    max-width: 100%; }
    @media (max-width: 968px) {
      .home .hero-wrapper .header {
        padding: 45px 30px 15px;
        max-width: none; } }
    .home .hero-wrapper .header h3 {
      line-height: 1.2; }
      .home .hero-wrapper .header h3 a {
        text-decoration: none; }
        .home .hero-wrapper .header h3 a:hover {
          text-decoration: underline; }
    .home .hero-wrapper .header .full-logo-wrapper {
      margin-top: 60px;
      float: left; }
      @media (max-width: 968px) {
        .home .hero-wrapper .header .full-logo-wrapper {
          float: none;
          margin-top: 0;
          width: auto; } }
    .home .hero-wrapper .header .mono-logo-wrapper {
      margin-top: 40px;
      float: right; }

.home #cursor {
  visibility: hidden !important; }

@media (max-width: 968px) {
  .project .wrapper,
  .feed .wrapper,
  .info .wrapper,
  .contact .wrapper,
  .about .wrapper {
    display: block; } }

@media (max-width: 756px) {
  .project .wrapper,
  .feed .wrapper,
  .info .wrapper,
  .contact .wrapper,
  .about .wrapper {
    padding: 15px; } }

.project .header,
.feed .header,
.info .header,
.contact .header,
.about .header {
  position: relative; }
  @media (max-width: 968px) {
    .project .header,
    .feed .header,
    .info .header,
    .contact .header,
    .about .header {
      width: 100%;
      max-width: none;
      flex: inherit; } }
  @media (max-width: 968px) {
    .project .header .full-logo-wrapper,
    .feed .header .full-logo-wrapper,
    .info .header .full-logo-wrapper,
    .contact .header .full-logo-wrapper,
    .about .header .full-logo-wrapper {
      margin-top: 0;
      margin-bottom: 30px; } }
  @media (max-width: 600px) {
    .project .header .full-logo-wrapper,
    .feed .header .full-logo-wrapper,
    .info .header .full-logo-wrapper,
    .contact .header .full-logo-wrapper,
    .about .header .full-logo-wrapper {
      margin-bottom: 0; } }

.project .content-container,
.feed .content-container,
.info .content-container,
.contact .content-container,
.about .content-container {
  flex: 1 0 60%;
  min-width: 60%;
  margin: 30px 15px 0; }
  @media (max-width: 968px) {
    .project .content-container,
    .feed .content-container,
    .info .content-container,
    .contact .content-container,
    .about .content-container {
      margin: 15px 0 0; } }

.project .hero-image,
.feed .hero-image,
.info .hero-image,
.contact .hero-image,
.about .hero-image {
  margin-bottom: 60px; }
  @media (max-width: 968px) {
    .project .hero-image,
    .feed .hero-image,
    .info .hero-image,
    .contact .hero-image,
    .about .hero-image {
      margin-bottom: 30px; } }
  @media (max-width: 968px) {
    .project .hero-image .article-caption,
    .feed .hero-image .article-caption,
    .info .hero-image .article-caption,
    .contact .hero-image .article-caption,
    .about .hero-image .article-caption {
      display: none; } }

.project .mono-logo-wrapper,
.feed .mono-logo-wrapper,
.info .mono-logo-wrapper,
.contact .mono-logo-wrapper,
.about .mono-logo-wrapper {
  flex: 1 0 5%;
  text-align: right;
  margin-top: 0;
  max-width: 100px; }
  @media (max-width: 968px) {
    .project .mono-logo-wrapper,
    .feed .mono-logo-wrapper,
    .info .mono-logo-wrapper,
    .contact .mono-logo-wrapper,
    .about .mono-logo-wrapper {
      display: none; } }

.project .project-info,
.feed .project-info,
.info .project-info,
.contact .project-info,
.about .project-info {
  margin-top: 60px;
  max-width: 350px; }
  @media (max-width: 968px) {
    .project .project-info,
    .feed .project-info,
    .info .project-info,
    .contact .project-info,
    .about .project-info {
      max-width: 100%;
      margin-top: 30px;
      display: none; } }
  .project .project-info ul,
  .feed .project-info ul,
  .info .project-info ul,
  .contact .project-info ul,
  .about .project-info ul {
    padding-bottom: 1em; }
    .project .project-info ul p,
    .feed .project-info ul p,
    .info .project-info ul p,
    .contact .project-info ul p,
    .about .project-info ul p {
      padding-bottom: 0; }
  .project .project-info p,
  .feed .project-info p,
  .info .project-info p,
  .contact .project-info p,
  .about .project-info p {
    font-size: 1em !important;
    padding-bottom: 0; }
  .project .project-info h3,
  .feed .project-info h3,
  .info .project-info h3,
  .contact .project-info h3,
  .about .project-info h3 {
    font-size: 1em;
    font-weight: bold; }
    .project .project-info h3 + p,
    .feed .project-info h3 + p,
    .info .project-info h3 + p,
    .contact .project-info h3 + p,
    .about .project-info h3 + p {
      padding-bottom: 1em; }

.project .project-info--mobile,
.feed .project-info--mobile,
.info .project-info--mobile,
.contact .project-info--mobile,
.about .project-info--mobile {
  display: none; }
  @media (max-width: 968px) {
    .project .project-info--mobile,
    .feed .project-info--mobile,
    .info .project-info--mobile,
    .contact .project-info--mobile,
    .about .project-info--mobile {
      max-width: 100%;
      margin-top: 30px;
      display: block;
      margin-bottom: 30px; } }

.project .project-title,
.feed .project-title,
.info .project-title,
.contact .project-title,
.about .project-title {
  margin-bottom: 15px; }

.project .project-tags,
.feed .project-tags,
.info .project-tags,
.contact .project-tags,
.about .project-tags {
  margin-bottom: 15px; }

.project .article,
.feed .article,
.info .article,
.contact .article,
.about .article {
  margin-bottom: 60px; }

.content-blocks {
  margin-top: 30px; }
  @media (max-width: 968px) {
    .content-blocks {
      margin-top: 0; } }
  @media (max-width: 968px) {
    .content-blocks .article:last-of-type {
      margin-bottom: 0; } }

.article-caption {
  display: inline-block;
  margin-top: 30px; }
  .article-caption.no-caption {
    display: none; }
  @media (max-width: 756px) {
    .article-caption {
      padding: 0 15px; } }

.back-arrow {
  width: 25px;
  position: fixed;
  left: 30px;
  bottom: 100px;
  opacity: 0; }
  .back-arrow.is-visible {
    opacity: 1; }
  @media (max-width: 968px) {
    .back-arrow {
      display: none; } }

@media (max-width: 968px) {
  .aa-feed {
    display: none !important; } }

@media (max-width: 968px) {
  .slick-slider {
    padding: 0;
    width: 100vw;
    margin-left: -30px; } }

@media (max-width: 756px) {
  .slick-slider {
    margin-left: -15px; } }

.read-more,
.read-less {
  text-transform: uppercase;
  text-decoration: none;
  font-family: "favorit bold", Helvetica, Arial, sans-serif;
  font-size: 1em;
  padding-bottom: 30px; }
  .read-more:hover,
  .read-less:hover {
    text-decoration: underline;
    cursor: pointer; }

.hidden-intro {
  display: none; }

.essay .wrapper, .default .wrapper {
  min-height: 100vh; }
  @media (max-width: 968px) {
    .essay .wrapper, .default .wrapper {
      flex-direction: column; } }
  @media (max-width: 756px) {
    .essay .wrapper, .default .wrapper {
      padding: 15px; } }

@media (max-width: 968px) {
  .essay header, .default header {
    width: 100%;
    max-width: none;
    flex: inherit; } }

@media (max-width: 968px) {
  .essay header h3.h6, .default header h3.h6 {
    display: none; } }

@media (max-width: 968px) {
  .essay header .full-logo-wrapper, .default header .full-logo-wrapper {
    margin-top: 0;
    margin-bottom: 30px; } }

.essay .project-title, .default .project-title {
  margin-bottom: 15px; }

.essay .project-tags, .default .project-tags {
  margin-bottom: 15px; }

.essay .essay-container, .default .essay-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .essay .essay-container .essay-column, .default .essay-container .essay-column {
    flex: 1 0 40%;
    margin-right: 30px;
    min-width: 300px; }
    .essay .essay-container .essay-column:last-of-type, .default .essay-container .essay-column:last-of-type {
      margin-right: 0; }

.essay .content-container, .default .content-container {
  flex: 1 0 60%;
  min-width: 60%;
  margin: 0 15px; }
  @media (max-width: 968px) {
    .essay .content-container, .default .content-container {
      margin: 15px 0 0; } }

.essay .mono-logo-wrapper, .default .mono-logo-wrapper {
  flex: 1 0 5%;
  text-align: right;
  margin-top: 0;
  max-width: 100px; }
  @media (max-width: 968px) {
    .essay .mono-logo-wrapper, .default .mono-logo-wrapper {
      display: none; } }

.essay .back-arrow, .default .back-arrow {
  position: fixed;
  left: 30px;
  bottom: 100px;
  opacity: 0; }
  .essay .back-arrow.is-visible, .default .back-arrow.is-visible {
    opacity: 1; }
  @media (max-width: 968px) {
    .essay .back-arrow, .default .back-arrow {
      display: none; } }

@media (max-width: 968px) {
  .essay .aa-feed, .default .aa-feed {
    display: none !important; } }

.feed {
  background-color: #0032a0;
  color: white; }
  .feed .wrapper {
    min-height: 100vh; }
  @media (max-width: 968px) {
    .feed .project-info {
      display: none; } }
  .feed .project-info--mobile {
    display: block; }
    @media (min-width: 968px) {
      .feed .project-info--mobile {
        display: none; } }
  .feed .link-container {
    padding-bottom: 60px; }
    .feed .link-container ul {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row; }
      @media (max-width: 968px) {
        .feed .link-container ul {
          max-height: inherit; } }
      .feed .link-container ul li {
        max-width: 400px;
        width: 40%;
        min-width: 300px;
        padding-bottom: 30px;
        margin-right: 30px; }
        @media (max-width: 968px) {
          .feed .link-container ul li {
            min-width: 100%; } }
        .feed .link-container ul li a {
          text-decoration: none;
          display: flex; }
          .feed .link-container ul li a:hover {
            text-decoration: underline; }
          .feed .link-container ul li a span:first-of-type {
            min-width: 90px; }
        .feed .link-container ul li figure {
          position: relative;
          padding-top: 10px; }
        .feed .link-container ul li img {
          margin-top: 8px;
          cursor: url("/uploads/noun_Plus_2310572.svg"), auto; }
          @media (min-width: 756px) {
            .feed .link-container ul li img:hover {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 666;
              width: 580px;
              padding-bottom: 30px; } }
  .feed .back-arrow--mob,
  .feed .h6 a,
  .feed svg,
  .feed a,
  .feed p {
    color: white;
    fill: white; }
  .feed .back-arrow polyline,
  .feed .back-arrow--mob polyline {
    fill: white; }
  .feed .back-arrow--mob {
    position: absolute;
    bottom: 0; }
  .feed .back-arrow {
    bottom: 30px; }

.default .content-container .project-info h3 {
  font-size: 1em !important;
  font-weight: bold !important; }

.default .content-container .project-info ul {
  margin: 0 0 15px; }

.default .mono-logo-wrapper {
  flex: 1 0 5%;
  text-align: right;
  margin-top: 0;
  max-width: 100px; }
  @media (max-width: 968px) {
    .default .mono-logo-wrapper {
      display: none; } }

.info {
  background-color: #f42434;
  color: white; }
  .info #full-logo,
  .info #mono-logo {
    fill: white; }
  .info .back-arrow svg,
  .info .back-arrow--mob svg {
    fill: white; }
  .info .back-arrow {
    position: fixed;
    left: 30px;
    bottom: 30px;
    opacity: 1; }
    @media (max-width: 968px) {
      .info .back-arrow {
        display: none; } }

.project-info--info span {
  margin-left: 30px; }

.project-info--info a {
  color: white; }

.project-info--info-mobile {
  display: none !important;
  margin-top: 0 !important; }
  @media (max-width: 968px) {
    .project-info--info-mobile {
      display: block !important;
      margin-bottom: 15px;
      order: 2; } }

@media (max-width: 968px) {
  .content-block--copy {
    order: 1; } }

.content-blocks--info {
  display: flex;
  margin-top: 0; }
  @media (max-width: 756px) {
    .content-blocks--info {
      flex-wrap: wrap; } }
  .content-blocks--info .content-block--info-images {
    width: 50%;
    margin-right: 30px; }
    @media (max-width: 756px) {
      .content-blocks--info .content-block--info-images {
        width: 100%;
        order: 2;
        margin-right: 0; } }
    .content-blocks--info .content-block--info-images img {
      margin-bottom: 30px; }
  .content-blocks--info .content-block--info-text {
    width: 50%; }
    @media (max-width: 756px) {
      .content-blocks--info .content-block--info-text {
        width: 100%;
        order: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap; } }

.contact {
  background-color: #f42434;
  color: black; }
  .contact #full-logo,
  .contact #mono-logo {
    fill: black; }
  .contact .back-arrow svg,
  .contact .back-arrow--mob svg {
    fill: black; }
  .contact .back-arrow {
    position: fixed;
    left: 30px;
    bottom: 30px;
    opacity: 1; }
    @media (max-width: 968px) {
      .contact .back-arrow {
        display: none; } }
  .contact p {
    padding-bottom: 1em !important; }
  @media (max-width: 756px) {
    .contact .content-container--info {
      margin-top: 40px; } }

.project-info--info span {
  margin-left: 30px; }

.project-info--info a {
  color: black; }

.project-info--info-mobile {
  display: none !important;
  margin-top: 0 !important; }
  @media (max-width: 968px) {
    .project-info--info-mobile {
      display: block !important;
      margin-bottom: 15px;
      order: 2; } }

@media (max-width: 968px) {
  .content-block--copy {
    order: 1; } }

.content-blocks--info {
  display: flex;
  margin-top: 0; }
  @media (max-width: 756px) {
    .content-blocks--info {
      flex-wrap: wrap; } }
  .content-blocks--info .content-block--info-images {
    width: 50%;
    margin-right: 30px; }
    @media (max-width: 756px) {
      .content-blocks--info .content-block--info-images {
        width: 100%;
        order: 2;
        margin-right: 0; } }
    .content-blocks--info .content-block--info-images img {
      margin-bottom: 30px; }
  .content-blocks--info .content-block--info-text {
    width: 50%; }
    @media (max-width: 756px) {
      .content-blocks--info .content-block--info-text {
        width: 100%;
        order: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap; } }

.about {
  background-color: white;
  color: black; }
  .about #full-logo,
  .about #mono-logo {
    fill: black; }
  .about .back-arrow svg,
  .about .back-arrow--mob svg {
    fill: black; }
  .about .back-arrow {
    position: fixed;
    left: 30px;
    bottom: 30px;
    opacity: 1; }
    @media (max-width: 968px) {
      .about .back-arrow {
        display: none; } }
  .about .project-info--info span {
    margin-left: 30px; }
  .about .project-info--info a {
    color: black; }
  .about .project-info--info-mobile {
    display: none !important;
    margin-top: 0 !important; }
    @media (max-width: 968px) {
      .about .project-info--info-mobile {
        display: block !important;
        margin-bottom: 15px;
        order: 2; } }
  .about .content-block--copy {
    margin-top: 80px; }
    @media (max-width: 968px) {
      .about .content-block--copy {
        order: 1; } }
    @media (max-width: 600px) {
      .about .content-block--copy {
        margin-top: 30px; } }
  .about .content-blocks--info {
    display: flex;
    margin-top: 0; }
    @media (max-width: 756px) {
      .about .content-blocks--info {
        flex-wrap: wrap; } }
    .about .content-blocks--info .content-block--info-text {
      width: 100%;
      max-width: 850px;
      margin: auto; }
      @media (max-width: 756px) {
        .about .content-blocks--info .content-block--info-text {
          width: 100%;
          order: 1;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          max-width: none; } }
  .about h3 {
    margin-bottom: 30px; }
    @media (max-width: 756px) {
      .about h3 {
        font-size: 1.5em;
        margin-bottom: 15px; } }
  .about p + h3 {
    margin-top: 30px; }
  .about p + h4 {
    margin-top: 30px; }
  .about h4 {
    font-size: 1.1em;
    font-family: "favorit bold", Helvetica, Arial, sans-serif;
    margin-bottom: 1em; }
  .about strong {
    font-family: "favorit reg", Helvetica, Arial, sans-serif;
    display: inline-block;
    padding-bottom: calc(1em / 2); }

.slider-overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 666;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  display: none;
  box-sizing: border-box; }
  .slider-overlay.is-visible {
    display: flex; }
  .slider-overlay .slider-close {
    position: absolute;
    top: 25px;
    right: 40px;
    width: 35px;
    z-index: 999; }
    .slider-overlay .slider-close:hover {
      cursor: pointer; }
    .slider-overlay .slider-close svg {
      width: 100%; }
  .slider-overlay .slider-overlay__container {
    min-width: 0 !important;
    width: 100%; }
  .slider-overlay .slick-initialized .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center; }
    .slider-overlay .slick-initialized .slick-slide:focus {
      outline: none; }
  .slider-overlay .slick-slide::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .slider-overlay .slick-slide img {
    vertical-align: middle;
    display: inline-block;
    max-height: 90vh;
    max-width: 80vw;
    margin: 0 auto;
    width: auto; }
  .slider-overlay ul {
    position: absolute;
    z-index: 667;
    height: 100vh;
    width: 100vw; }
    .slider-overlay ul li {
      margin-top: -11px; }
      .slider-overlay ul li:hover {
        cursor: pointer; }
      .slider-overlay ul li.overlay-prev {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 50vw;
        z-index: 668; }
        .slider-overlay ul li.overlay-prev:hover {
          cursor: url("/uploads/slider-arrow-left.svg"), auto; }
      .slider-overlay ul li.overlay-next {
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        width: 50vw;
        z-index: 668; }
        .slider-overlay ul li.overlay-next:hover {
          cursor: url("/uploads/slider-arrow-right.svg"), auto; }

.article {
  margin-bottom: 60px; }
  @media (max-width: 756px) {
    .article {
      margin-bottom: 30px !important; } }
  .article.hide {
    display: none; }
  .article.active {
    display: flex; }
  .article h3 {
    font-size: 1.5em; }
    .article h3:last-of-type {
      margin-bottom: 30px; }
  .article a {
    text-transform: uppercase;
    text-decoration: none;
    font-family: "favorit bold", Helvetica, Arial, sans-serif; }
    .article a:hover {
      text-decoration: underline; }
  .article .news a, .article .news a h3 {
    color: #f42434;
    text-decoration-color: #f42434; }

@media (max-width: 756px) {
  .article-image, .article-essay-bg {
    width: 100vw !important;
    margin-left: -15px !important;
    order: 1 !important;
    margin-bottom: 30px; } }

.no-image .article-caption {
  margin-top: 0 !important; }

@media (max-width: 756px) {
  .no-image {
    margin-bottom: 0 !important; } }

@media (max-width: 756px) {
  .no-padding .article-image {
    margin-bottom: 0; } }

.article-info h3 {
  text-transform: none; }

.article-info p:last-of-type {
  padding-bottom: 0; }

@media (max-width: 756px) {
  .article-info {
    width: 100% !important;
    order: 2 !important; } }

.article--full {
  flex-direction: column; }
  .article--full .article-caption {
    margin-top: 20px; }
    @media (max-width: 756px) {
      .article--full .article-caption {
        margin-top: 30px; } }
    .article--full .article-caption:empty {
      margin-top: 0; }
  .article--full .article-image {
    width: 100%;
    margin-bottom: 30px; }
  .article--full .article-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media (max-width: 756px) {
      .article--full .article-info {
        flex-direction: column;
        justify-content: flex-start; } }
    .article--full .article-info .article-desc {
      max-width: 45%; }
      @media (max-width: 756px) {
        .article--full .article-info .article-desc {
          max-width: 100%; } }

.article--40-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 756px) {
    .article--40-left {
      flex-direction: column; } }
  .article--40-left .article-image {
    width: 45%;
    order: 1; }
  .article--40-left .article-info {
    order: 2;
    width: 45%; }

.article--40-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 756px) {
    .article--40-right {
      flex-direction: column; } }
  .article--40-right .article-image {
    width: 45%;
    order: 2; }
  .article--40-right .article-info {
    order: 1;
    width: 45%; }

.article--60-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 756px) {
    .article--60-left {
      flex-direction: column; } }
  .article--60-left .article-image {
    width: 65%;
    order: 1; }
  .article--60-left .article-info {
    order: 2;
    width: 25%; }
    @media (max-width: 1280px) {
      .article--60-left .article-info {
        width: 30%; } }
    @media (max-width: 968px) {
      .article--60-left .article-info {
        width: 25%; } }

.article--60-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 756px) {
    .article--60-right {
      flex-direction: column; } }
  .article--60-right .article-image {
    width: 65%;
    order: 2; }
  .article--60-right .article-info {
    order: 1;
    width: 25%; }
    @media (max-width: 1280px) {
      .article--60-right .article-info {
        width: 30%; } }
    @media (max-width: 968px) {
      .article--60-right .article-info {
        width: 25%; } }

.article--essay-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 756px) {
    .article--essay-right {
      flex-direction: column; } }
  .article--essay-right .article-essay-bg {
    width: 45%;
    order: 2;
    text-align: right; }
    .article--essay-right .article-essay-bg svg {
      max-width: 100%; }
  .article--essay-right .article-info {
    order: 1;
    width: 45%; }

.article--essay-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 756px) {
    .article--essay-left {
      flex-direction: column; } }
  .article--essay-left .article-essay-bg {
    width: 45%;
    order: 1;
    text-align: left; }
    .article--essay-left .article-essay-bg svg {
      max-width: 100%; }
  .article--essay-left .article-info {
    order: 2;
    width: 45%; }

.article--slider {
  position: relative; }
  .article--slider ul {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%; }
    .article--slider ul li {
      margin-top: -11px; }
      .article--slider ul li:hover {
        cursor: pointer; }
      .article--slider ul li.prev {
        margin-left: -35px; }
        @media (max-width: 968px) {
          .article--slider ul li.prev {
            margin-left: 10px; } }
        @media (max-width: 756px) {
          .article--slider ul li.prev {
            margin-left: -5px; } }
      .article--slider ul li.next {
        margin-right: -35px; }
        @media (max-width: 968px) {
          .article--slider ul li.next {
            margin-right: 10px; } }
        @media (max-width: 756px) {
          .article--slider ul li.next {
            margin-right: -5px; } }

.article--slider--left {
  width: 60%;
  margin-right: auto; }
  @media (max-width: 968px) {
    .article--slider--left {
      width: inherit; } }

.article--slider--right {
  width: 60%;
  margin-left: auto; }
  @media (max-width: 968px) {
    .article--slider--right {
      width: inherit; } }

.mobile-menu {
  width: 0px;
  transition: 0.5s width linear;
  display: block;
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 666;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  overflow-x: hidden;
  transition: 0.3s width ease; }
  .mobile-menu .mobile-menu--close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 66666;
    width: 40px; }
    @media (max-width: 600px) {
      .mobile-menu .mobile-menu--close {
        top: 20px;
        right: 8px; } }
    .mobile-menu .mobile-menu--close:hover {
      cursor: pointer; }
    .mobile-menu .mobile-menu--close svg {
      fill: white; }
  .mobile-menu.show {
    width: 300px; }
  .mobile-menu .first-tier {
    padding-top: 130px;
    font-family: "favorit bold", Helvetica, Arial, sans-serif; }
    @media (max-width: 600px) {
      .mobile-menu .first-tier {
        padding-top: 80px; } }
    .mobile-menu .first-tier h3.h6 {
      display: block !important;
      font-size: 1em; }
      .mobile-menu .first-tier h3.h6 a {
        color: black !important; }
        .mobile-menu .first-tier h3.h6 a:hover {
          text-decoration: none; }
  .mobile-menu .second-tier {
    padding-top: 12px;
    padding-bottom: 40px; }
  .mobile-menu ul {
    padding: 0 0 20px 20px;
    width: 300px; }
    .mobile-menu ul li {
      display: block;
      white-space: nowrap;
      color: black !important; }
      .mobile-menu ul li a {
        color: black !important;
        font-size: 1em;
        padding-bottom: 3px;
        text-decoration: none; }
        .mobile-menu ul li a:hover {
          text-decoration: none; }

.aa-feed {
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: none; }
  @media (max-width: 968px) {
    .aa-feed {
      display: none; } }
  .aa-feed a {
    color: #f42434;
    text-decoration: none;
    display: flex; }
    .aa-feed a.blue {
      color: #0032a0; }
    .aa-feed a span {
      position: relative;
      top: -2px;
      margin-right: 4px; }
  .aa-feed.is-visible {
    display: block; }
  .aa-feed:hover {
    cursor: pointer; }

.aa-feed__modal {
  display: none;
  background-color: #0032a0;
  color: white;
  padding: 30px;
  position: fixed;
  bottom: 60px;
  left: 100px; }
  .aa-feed__modal ul {
    max-width: 600px; }
    .aa-feed__modal ul li {
      padding-bottom: 5px; }
  @media (max-width: 968px) {
    .aa-feed__modal {
      display: none; } }
  .aa-feed__modal.active {
    display: block; }
  .aa-feed__modal a {
    color: white;
    text-decoration: none;
    display: flex; }
    .aa-feed__modal a:hover {
      text-decoration: underline; }
    .aa-feed__modal a span:first-of-type {
      min-width: 90px; }

.aa-feed__heading a:hover {
  text-decoration: underline; }

.aa-feed__heading:first-of-type {
  margin-bottom: 15px; }

.aa-feed__heading:last-of-type {
  margin-top: 15px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow svg {
  width: 50px; }
